<template>
    <div class="campus-health">
        <heads></heads>
        <img class="top-bg" src="../../assets/images/campus_health_detail_top_bg.png" alt="">
        <div class="campus-health-container">
            <div class="content" v-if="article.name != null && article.name != ''">
                <div v-html="article.content"></div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getArticle } from "@api/public";
export default {
    components: {
        heads,
        foot,
    },
    data() {
        return {
            article: {}
        };
    },
    watch: {
        $route(to, from) {
            this.$router.go(0);
        },
    },
    mounted: function () {
        let that = this;
        getArticle(this.$route.query).then(function (res) {
            that.$set(that, "article", res.data);
        });
    },
    created: function () {
        document.title = this.$route.meta.title;
    },
};
</script>

<style scoped>
.campus-health {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #e3d4be;
}

.campus-health .top-bg {
    width: 1920px;
    height: 550px;
}

.campus-health .campus-health-container {
    position: relative;
    width: 1920px;
    height: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url(../../assets/images/campus_health_detail_bg.png) 1920px 1600px no-repeat;
    background-size: cover;
    background-position: center 0;
}

.campus-health .campus-health-container .content {
    position: relative;
    width: 960px;
    height: 1460px;
    overflow-y: auto;
    padding: 15px auto;
}
</style>